import { useState } from "react";
import integrations from "../../shared/integrations";
import { motion } from "framer-motion";
import FormField from "../FormField";
import TextInput from "../TextInput";
import Button from "../Button";
import { RootState } from "../../reducers";
import { useMutation, useQuery } from "react-query";
import { createIntegration, fetchPerms } from "../../shared/queries";
import { toast } from "react-toastify";
import addIntegrationSchema from "../../shared/schema/addIntegrationSchema";
import { getOldToken, getAuthToken } from "../../shared/utils/tokenStore";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SelectInput from "../SelectInput";
import { BaseSelectItem } from "../../types";
import CircleSpinner from "../CircleSpinner";

export default function IntegrationWizard({
  onComplete,
}: {
  onComplete: () => void;
}) {
  const { t } = useTranslation();

  const history = useHistory();

  const [validationError, setValidationError] = useState<any>(null);

  const [currentChannel, setCurrentChannel] = useState<any | null>(null);
  const [account, setAccount] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { data, isLoading } = useQuery("accounts", fetchPerms);
  const { profile } = useSelector((state: RootState) => state.login);

  const mutation = useMutation(
    () =>
      createIntegration({
        action: "new_user",
        integr_type: currentChannel ? currentChannel.key : null,
        integr_secret: password,
        integr_token: token,
        user_key: account,
      }),
    {
      onSuccess: (data: any) => {
        toast.success(t("general.created"));
        if (data && data.results && data.results.lastrowid) {
          history.push(
            "/integrations/" + currentChannel.key + "/" + data.results.lastrowid
          );
        }
        onComplete();
      },
      onError: (error: Error) => {
        toast.error(t(error.message) || t("general.requestError"));
      },
    }
  );

  function handleSubmit() {
    const data = {
      action: "new_user",
      integr_type: currentChannel ? currentChannel.key : null,
      integr_secret: password,
      integr_token: token,
      user_key: account,
    };
    const { error, value } = addIntegrationSchema.validate(data);
    if (error) {
      setValidationError(error);
      toast.error(t("general.invalidFields"));
    } else {
      setValidationError(null);
      mutation.mutate();
    }
  }

  const handleRouting = () => {
    setButtonDisabled(true);
    var href =
      "https://hub-api.booking.com/v1/oauth2/authorize?response_type=code&client_id=91e63b7348a11bf98f202eeafc50b4d1&redirect_uri=https://www.vikey.it/api/oauthcallback&state=" +
      getOldToken();
    if (currentChannel.key === "CLOUDBEDS") {
      href =
        "https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=vikey_hHUIzFjqPBC53fTJgxoXct0e&redirect_uri=https://my.vikey.it/integrations-callback-out/CLOUDBEDS&response_type=code&scope=read%3Aguest+write%3Aguest+read%3Ahotel+read%3Areservation+write%3Areservation+read%3Aroom&state=" +
        getAuthToken();
    }
    if (currentChannel.key === "AIRBNB") {
      href =
        "https://www.airbnb.com/oauth2/auth?client_id=48rd25d02ay4zz41xqi8indge&redirect_uri=https%3A%2F%2Fapi.vikey.it%2Fapi%2Fv3%2Fintegr%2Fairbnboauthcallback&scope=basic_reservation_read%2Cbasic_listing_read%2Cbasic_profile_read%2Cguest_basic_profile_read%2Creservations_read%2Cmessages_read%2Cmessages_write&state=" +
        getAuthToken();
    }
    if (currentChannel.key === "OCTORATE2") {
      href =
        "https://admin.octorate.com/octobook/identity/oauth.xhtml?client_id=public_66d3bc892ccf44eebced0af86e31c959&redirect_uri=https://api.vikey.it/api/v3/integr/octorateoauthcallback&state=" +
        getAuthToken();
    }
    if (currentChannel.key === "BOOKINGSYNC") {
      href =
        "https://www.bookingsync.com/oauth/authorize?client_id=8a6e1eea976c0004632722ee4f0476aee5725731319884e2ea2b84fcc2438aa8&response_type=code&scope=bookings_read%20rentals_read%20clients_read&redirect_uri=https://api.vikey.it/api/v3/integr/oauthsmilycallback&state=" +
        getAuthToken();
    }
    if (currentChannel.key === "BBPLANNER") {

      const esKey = profile?.user_key;
      href =
        "https://bbplanner.com/calendario/login.php?esCode=VIKEY&esKey=" + esKey + "&esRedirectUrl=https%3A%2F%2Fapi.vikey.it%2Fapi%2Fv3%2Fintegr%2Fbbplanneroauthcallback"
    }
    document.location.href = href;
    setTimeout(() => setButtonDisabled(false), 10000);
  };
  return (
    <div className={"w-full"}>
      {currentChannel && (
        <div className="pt-6 px-6">
          <button onClick={() => setCurrentChannel(null)} className="mb-6">
            {"<-"} {t("integrationWizard.backToChannels")}
          </button>
        </div>
      )}

      <div className="flex items-center justify-between">
        <div className="pb-6 px-6 mt-4">
          <div className="font-bold text-2xl">
            {t("integrationWizard.addIntegration")}
          </div>
          {!currentChannel && (
            <div className="text-gray-600 mt-2">
              {t("integrationWizard.chooseChannel")}
            </div>
          )}
          <div className="pb-4 mt-6">
            {currentChannel &&
              currentChannel.desc != null &&
              t(currentChannel.desc)}
          </div>
        </div>

        <div className={"hidden md:block pr-6"}>
          {currentChannel && (
            <img className={"w-36"} src={"/" + currentChannel.logo} />
          )}
        </div>
      </div>

      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        className="flex flex-wrap"
      >
        {!currentChannel &&
          integrations.map((integration: any, index: number) => {
            return (
              <div
                key={index}
                onClick={() => setCurrentChannel(integration)}
                className={`py-1 flex-col cursor-pointer hover:bg-gray-100 w-1/2 lg:w-1/4 border-b border-l flex items-center justify-center ${
                  integration.key === "SITEMINDER" &&
                  ![
                    "Gh1t8R8PdGW4gDX10000000000000000",
                    "Si2rjw4OrMoFWLcR0000000000000000",
                    "Rb3aFe4wNTMeHh0H0000000000000000",
                    "Fs6EWACuQrM1ESyJ0000000000000000",
                    "LA1zQgqvLyjgXJZP0000000000000000",
                    "CE6iuQI1b4Fe5gdp0000000000000000",
                    "VT1xfjPoskpbCWGj0000000000000000",
                  ].includes(profile?.user_key ?? "none") &&
                  "hidden"
                }`}
              >
                <img
                  className={"w-36"}
                  src={"/" + integration.logo}
                  alt={integration.key}
                />
              </div>
            );
          })}
      </motion.div>

      {currentChannel && (
        <motion.div
          className={"px-6 pb-6 relative"}
          initial={{
            top: 20,
            opacity: 0,
          }}
          animate={{
            top: 0,
            opacity: 1,
          }}
          transition={{
            duration: 0.3,
          }}
        >
          {!currentChannel.oAuth ? (
            <div className="mt-4 flex flex-col space-y-4">
              <FormField
                label={"Account"}
                errors={validationError}
                errorKey={"user_key"}
              >
                <SelectInput<BaseSelectItem, false>
                  onChange={(item) => {
                    if (item) setAccount(item.value);
                  }}
                  options={
                    data && data.results
                      ? data.results.map((account) => {
                          return {
                            label: account.o_name,
                            value: account.o_user_key,
                          };
                        })
                      : []
                  }
                />
              </FormField>
              {currentChannel.token && (
                <FormField
                  errors={validationError}
                  errorKey={"integr_token"}
                  label={currentChannel.token}
                >
                  {currentChannel.name === 'suite5' && <p className="text-gray-500 font-medium"> Url</p>}
                  <TextInput value={token} onChange={(val) => setToken(val)} />
                </FormField>
              )}

              {currentChannel.secret && (
                <FormField
                  errors={validationError}
                  errorKey={"integr_secret"}
                  label={currentChannel.secret}
                >
                  <TextInput
                    value={password}
                    onChange={(val) => setPassword(val)}
                  />
                </FormField>
              )}
            </div>
          ) : (
            <div className={"w-72 mt-2"}>
              <button
                className={`${
                  !buttonDisabled
                    ? "text-cyan-800 bg-cyan-50 border-cyan-600 hover:bg-cyan-200"
                    : "text-gray-800 bg-gray-50 border-gray-600 hover:bg-gray-200 opacity-40"
                } rounded-2xl px-3 py-2 font-medium border  flex items-center space-x-2`}
                disabled={buttonDisabled}
                onClick={handleRouting}
              >
                {!buttonDisabled ? (
                  <>
                    <img
                      className={"h-10 md:block"}
                      src={
                        "/images/logos/" +
                        currentChannel.key.toLowerCase() +
                        "small.png"
                      }
                      alt="Small channel logo"
                    />
                    <div>Connect with {currentChannel.key}</div>
                  </>
                ) : (
                  <CircleSpinner color="primary" width={40} height={40} />
                )}
              </button>
            </div>
          )}

          {currentChannel &&
            !currentChannel.oAuth &&
            (currentChannel.token || currentChannel.secret) && (
              <Button
                loading={mutation.isLoading}
                className={"mt-6"}
                label={t("integrationWizard.activate")}
                onClick={handleSubmit}
              />
            )}
        </motion.div>
      )}
    </div>
  );
}
