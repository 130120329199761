import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DomainContext } from "../DomainContext";
import MenuItem from "./MenuItem";
import { useQuery } from "react-query";
import { fetchAllModules } from "../shared/queries";
import { moduleId } from "../shared/utils/config";
import { RootState } from "../reducers";
import { useSelector } from "react-redux";

export default function NavigationMenu({
  isMini = false,
}: {
  isMini: boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { profile } = useSelector((state: RootState) => state.login);

  const { appType } = useContext(DomainContext);
  const [channelManager, setChannelManager] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery<any>(["navigationMenu"], () => {
    return fetchAllModules().then((data: any) => {
      const moduleFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.channel_manager
      );
      if (moduleFinded) {
        setChannelManager(true)
      } else {
        setChannelManager(false)
      }
      return data;
    });
  });

  return (
    <>
      <MenuItem
        className={"mt-6"}
        icon={"home"}
        active={history && history.location.pathname === "/dashboard"}
        mini={isMini}
        label={t("navigation.dashboard")}
        to={"/dashboard"}
      />

      <MenuItem
        icon={"bulletList"}
        label={t("navigation.modules")}
        mini={isMini}
        active={history && history.location.pathname.indexOf("modules") > -1}
        to={"/modules"}
        sided
      />

      <MenuItem
        icon={"cart"}
        label={t("navigation.services")}
        mini={isMini}
        active={
          history &&
          (history.location.pathname.indexOf("services") > -1 ||
            history.location.pathname.indexOf("orders") > -1)
        }
        to={"/orders"}
      />
      {/* <MenuItem
        icon={"shopping-bag"}
        label={t("navigation.orders")}
        mini={isMini}
        active={history && history.location.pathname.indexOf("orders") > -1}
        to={"/orders"}
      /> */}
      <MenuItem
        icon={"chip"}
        active={history && history.location.pathname === "/domotics"}
        mini={isMini}
        label={t("navigation.domotics")}
        to={"/domotics"}
      />

      <MenuItem
        icon={"support"}
        label={t("general.support")}
        to={"/support"}
        active={history && history.location.pathname.indexOf("support") > -1}
        mini={isMini}
      />

      {!isMini && (
        <div className={"text-gray-500 pl-6 text-sm font-medium mt-4"}>
          {t("navigation.management")}
        </div>
      )}

      <MenuItem
        className={"mt-4"}
        icon={"book-open"}
        label={t("navigation.reservations")}
        mini={isMini}
        active={
          history && history.location.pathname.indexOf("reservations") > -1
        }
        to={"/reservations"}
      />
      <MenuItem
        // className={'mt-4'}
        icon={"office-building"}
        label={t("navigation.apartments")}
        mini={isMini}
        active={history && history.location.pathname.indexOf("apartments") > -1}
        to={"/apartments"}
      />
      <MenuItem
        icon={"calendar"}
        label={t("navigation.calendar")}
        mini={isMini}
        active={history && history.location.pathname === "/calendar"}
        to={"/calendar"}
      />
      {(profile?.user_key === "VT1xfjPoskpbCWGj0000000000000000" ||
        profile?.user_key === "DV8FOzJ5cjMSI67t0000000000000000" ||
        profile?.user_key === "CC25u7xWn6HgHBep0000000000000000" ||
        profile?.user_key === "0XW4Y4UCOjL6Vl000000000000000000") &&
        <MenuItem
          icon={"calendar"}
          label={`${t("navigation.calendar")} - Test`}
          mini={isMini}
          active={history && history.location.pathname === "/calendar-new"}
          to={"/calendar-new"}
        />
      }
      {channelManager && <MenuItem
        icon={"wrench"}
        label={t("Channel Manager")}
        mini={isMini}
        active={history && history.location.pathname === "/channel-manager"}
        to={"/channel-manager"}
      />}

      {/* {
        !isMini &&
        <div className={'text-gray-500 pl-6 text-sm font-medium mt-4'}>
          {t('navigation.administration')}
        </div>
      } */}

      {appType === "vikey" && (
        <MenuItem
          icon={"document-text"}
          label={t("navigation.invoices")}
          to={"/invoices"}
          active={history && history.location.pathname === "/invoices"}
          mini={isMini}
        />
      )}

      <MenuItem
        className={"mt-4"}
        icon={"adjustments"}
        label={t("navigation.administration")}
        to={"/configuration/bureaucracy"}
        active={
          history && history.location.pathname.indexOf("configuration") > -1
        }
        mini={isMini}
      />
    </>
  );
}
