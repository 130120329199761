import { useQuery } from "react-query";
import {
  fetchAllModules,
  fetchCalendariCal,
  fetchDirectLocals,
  fetchReservations,
  fetchRestrictions,
} from "../shared/queries";
import dayjs from "dayjs";
import { BaseSelectItem, CalendarDay, Local, ReservationTable } from "../types";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ReservationDetail from "../components/calendar/ReservationDetail";
import ApartmentRowNEW from "../components/calendar/ApartmentRowNEW";
import DatePicker from "../components/DatePicker";
import weekday from "dayjs/plugin/weekday";
import { useHistory } from "react-router-dom";
import Icon from "../components/Icon";
// import useBreadcrumbs from "../hooks/useBreadcrumbs";
import { useTranslation } from "react-i18next";
import Checkbox from "../components/Checkbox";
import SelectInput from "../components/SelectInput";
import { moduleId } from "../shared/utils/config";
import SideBarChannel from "../components/calendar/SideBarChannel";
import Button from "../components/Button";
import SingleAptCalendar from "../components/calendar/SingleAptCalendar";
import ModalOverride from "../components/calendar/ModalOverride";
import i18n from "../langs";
import "dayjs/locale/it";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
// TODO pagination import Pagination from "../components/Pagination";

dayjs.extend(weekday);

export default function CalendarNEW() {
  const history = useHistory();
  const { t } = useTranslation();

  const [days, setDays] = useState<CalendarDay[]>([]);
  const [openTabCal, setOpenTabCal] = useState(false);
  const [startDate, setStartDate] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().add(15, "days").format("YYYY-MM-DD")
  );
  const [currentReservation, setCurrentReservation] = useState<any>();
  const [tempLocals, setTempLocals] = useState<string[]>([]);
  const [iCalButton, setiCalButton] = useState(false);
  const [viewIcal, setViewIcal] = useState<boolean>(false);
  const [disableiCal, setDisableiCal] = useState<boolean>(true);
  const [selectStart, setSelectStart] = useState<any>([]);
  const [timeLine, setTimeLine] = useState(false);
  const [disableChannelBtn, setDisableChannelBtn] = useState(true);
  const [selectChannel, setSelectChannel] = useState<any>();
  const [selectStatus, setSelectStatus] = useState<any>();

  // TODO pagination const limitPage = 15;
  // TODO pagination const [currentPage, setCurrentPage] = useState(1);
  // TODO pagination const [pageData, setPageData] = useState<any>([]);
  // TODO pagination const [totalData, setTotalData] = useState(0);

  function recalcDays() {
    const ds = [];
    let tempDate = dayjs(startDate).locale(i18n.language).clone();
    while (tempDate.isBefore(endDate)) {
      ds.push({
        date: tempDate.format("DD-MM-YYYY"),
        weekday: tempDate.format("dd"),
        day: tempDate.format("DD"),
        dayNumber: tempDate.weekday(),
      } as CalendarDay);
      tempDate = tempDate.add(1, "day");
    }
    setDays(ds);
  }

  // useBreadcrumbs([{ label: "Calendar", route: "/calendar" }]);

  const { data, refetch: refetchReservations } = useQuery(
    ["calendar", timeLine, selectChannel],
    () =>
      fetchReservations(
        [
          {
            key: "1",
            label: "",
            active: true,
            value: timeLine
              ? `${dayjs(startDate)
                  .subtract(1, "month")
                  .format("YYYY MM DD")} >>> ${endDate}`
              : `${dayjs(startDate)
                  .subtract(15, "days")
                  .format("YYYY MM DD")} >>> ${dayjs(endDate).format(
                  "YYYY MM DD"
                )}`,
          },
          {
            key: "22",
            label: "",
            active: true,
            value: "0",
          },
        ],
        undefined,
        [],
        800
      )
  );

  const { data: locals } = useQuery(["localsList"], () => fetchDirectLocals(), {
    onSuccess: (data: any) => {
      const test: string[] = [];
      data.results.map((acc: any) => {
        test.push(acc.local_key as string);
        return test;
      });
      setTempLocals(test);
      // TODO pagination setPageData(test)
      // TODO pagination setTotalData(data?.tot_locals)
      return data;
    },
  });

  function getLocalReservations(local: Local): ReservationTable[] {
    if (data) {
      const dataTemp = data.data
        .filter((d) => d.local === local.name)
        ?.filter((d) => {
          if (selectChannel && selectChannel.value) {
            return d.source?.toLowerCase() === selectChannel?.value;
          }
          return d;
        })
        ?.filter((d) => {
          if (selectStatus && selectStatus.value) {
            return d.checkin_status?.toLowerCase() === selectStatus?.value;
          }
          return d;
        });
      const secondDataTemp = getLocalReservationsiCal(local);
      const dataTempFinal = [...dataTemp, ...secondDataTemp];
      /* return data.data.filter((d) => {
        return d.local === local.name;
      }); */
      if (secondDataTemp.length > 0 && viewIcal) {
        return dataTempFinal;
      }
      return dataTemp;
    }
    return [];
  }

  const { data: iCalDT } = useQuery<any>(
    ["icalDT", tempLocals],
    () => fetchCalendariCal(tempLocals),
    {
      enabled: tempLocals.length > 0 && iCalButton,
    }
  );

  function getLocalReservationsiCal(local: Local): ReservationTable[] {
    if (iCalDT) {
      return iCalDT?.results
        ?.filter((d: any) => {
          return d.local_key === local.local_key;
        })
        ?.filter((d: any) => {
          if (selectChannel && selectChannel.value) {
            return d.source?.toLowerCase() === selectChannel?.value;
          }
          return d;
        })
        ?.filter((d: any) => {
          if (selectStatus && selectStatus.value) {
            return d.checkin_status?.toLowerCase() === selectStatus?.value;
          }
          return d;
        });
    }
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: dataModules } = useQuery([], () => {
    return fetchAllModules().then((data) => {
      const moduleCalendarFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.calendar
      );
      if (moduleCalendarFinded) {
        setiCalButton(true);
        if (moduleCalendarFinded?.active_locals_count === 0) {
          setDisableiCal(true);
        } else {
          setDisableiCal(false);
        }
      } else {
        setiCalButton(false);
        setDisableiCal(true);
      }
      const moduleChannelFinded = data?.modules?.find(
        (module: any) => module?.id === moduleId.channel_manager
      );
      if (moduleChannelFinded) {
        if (moduleChannelFinded?.active_locals_count === 0) {
          setDisableChannelBtn(true);
        } else {
          setDisableChannelBtn(false);
        }
      } else {
        setDisableChannelBtn(true);
      }

      return data;
    });
  });

  const { data: restrictions, refetch: refetchRestrictions } = useQuery(
    ["restrictions", startDate, selectStart, timeLine],
    () => fetchRestrictions(timeLine ? 31 : 14, startDate, null)
  );

  const changeStartLocal = (dateTemp: any, local: any) => {
    setStartDate(dateTemp);
    getLocalReservations(local);
  };
  const changeEndLocal = (dateTemp: any, local: any) => {
    setEndDate(dateTemp);
    getLocalReservations(local);
  };
  useEffect(() => {
    const dataStorage = localStorage.getItem("calendar_filteredLocals");
    if (dataStorage) {
      setSelectStart(JSON.parse(dataStorage));
      // setStartDate(dayjs().startOf('month').format("YYYY-MM-DD"))
      // setEndDate(dayjs().endOf('month').format("YYYY-MM-DD"))
    }
  }, []);

  useEffect(() => {
    recalcDays();
    refetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, i18n.language]);

  return (
    <div className={"select-none"}>
      <div className="w-full mb-5 md:flex justify-between items-center text-sm ml-4">
        <div className="flex align-middle">
          <SelectInput<BaseSelectItem, true>
            value={
              selectStatus && selectStatus?.value
                ? selectStatus
                : {
                    label: t("selector.selectStatus"),
                    value: null,
                  }
            }
            // placeholder="Seleziona locals da visualizzare.."
            onChange={(item) => {
              if (item) {
                setSelectStatus(item);
              }
            }}
            options={[
              {
                label: t("resv.notNecessary"),
                value: "noneed",
              },
              {
                label: t("resv.notExecuted"),
                value: "wait",
              },
              {
                label: t("resv.waitingConfirmation"),
                value: "pend",
              },
              {
                label: t("resv.confirmed"),
                value: "ok",
              },
            ]}
          />
          <div className="md:mt-0 mt-3">
            <Button
              className={"ml-1"}
              flat={!selectStatus?.value}
              color={!selectStatus?.value ? "primary" : "negative"}
              disabled={!selectStatus?.value}
              onClick={() => {
                setSelectStatus({
                  label: null,
                  value: null,
                });
              }}
              label=""
              icon="x"
              iconSize="18px"
            />
          </div>
        </div>
        <div className="mr-8">
          <Button
            size="small"
            onClick={() => {
              history.push("/reservations/new");
            }}
          >
            <span className={"hidden lg:block"}>
              {t("reservations.newReservation")}
            </span>
            <span className={"block lg:hidden"}>
              <Icon name={"document-add"} size={"26px"} />
            </span>
          </Button>
        </div>
      </div>
      <div className="md:flex md:space-y-0 space-y-0.5 justify-start gap-5 text-xs font-bold ml-4 mb-5">
        <div
          className={
            "font-medium flex items-center text-gray-800 bg-gray-100 rounded-full p-1 border-2 border-gray-800"
          }
        >
          <div className={"w-3 h-3 bg-gray-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.notNecessary")}
        </div>
        <div
          className={
            "font-medium flex items-center text-red-800 bg-red-200 rounded-full p-1 border-2 border-red-800"
          }
        >
          <div className={"w-3 h-3 bg-red-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.notExecuted")}
        </div>
        <div
          className={
            "font-medium flex items-center text-yellow-800 bg-yellow-300 rounded-full p-1 border-2 border-yellow-800"
          }
        >
          <div className={"w-3 h-3 bg-yellow-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.waitingConfirmation")}
        </div>

        <div
          className={
            "font-medium flex items-center text-green-800 bg-green-100 rounded-full p-1 border-2 border-green-800"
          }
        >
          <div className={"w-3 h-3 bg-green-800 mr-2 rounded-full"}></div>{" "}
          {t("resv.confirmed")}
        </div>
      </div>
      <div className="md:flex p-4 pt-0 space-x-2 lg:space-x-0 items-center justify-between">
        <div className="flex items-center space-x-4">
          {/* <div className="font-bold text-gray-800 text-2xl">
            {t("navigation.calendar")}
          </div> */}
          <div className="font-bold text-gray-800 text-2xl">
            {t("reservations.date")}
          </div>

          <div>
            <DatePicker
              value={startDate}
              inputFormat={"YYYY-MM-DD"}
              outputFormat={"YYYY-MM-DD"}
              displayFormat={"DD-MM-YYYY"}
              onChange={(value) => {
                setStartDate(
                  dayjs(value).subtract(1, "days").format("YYYY-MM-DD")
                );
                setEndDate(
                  dayjs(value)
                    .subtract(1, "days")
                    .add(15, "days")
                    .format("YYYY-MM-DD")
                );
              }}
            />
          </div>
          {iCalButton && (
            <div className="bg-white border-gray-300 border-2 rounded-xl px-2">
              <Checkbox
                disabled={disableiCal}
                size="sm"
                checked={viewIcal}
                onChange={() => setViewIcal((prev) => !prev)}
                label={t("calendar.viewiCal")}
              />
            </div>
          )}
        </div>

        <div className="flex gap-3">
          <Button
            label={t("channelManager.bulkUpdate")}
            disabled={disableChannelBtn}
            onClick={() => setOpenTabCal((prev) => !prev)}
          />
        </div>
      </div>
      <div className="w-full mb-5 md:flex justify-start items-center gap-5 text-sm ml-4">
        <div className="w-96 flex align-middle">
          <SelectInput<BaseSelectItem, true>
            value={
              selectChannel && selectChannel.value
                ? selectChannel
                : {
                    label: t("selector.selectChannel"),
                    value: null,
                  }
            }
            // placeholder="Seleziona locals da visualizzare.."
            onChange={(item) => {
              if (item) {
                setSelectChannel(item);
              }
            }}
            options={[
              {
                label: "Booking.com",
                value: "booking",
              },
              {
                label: "Airbnb",
                value: "airbnb",
              },
            ]}
          />
          <div className="">
            <Button
              className={"ml-1"}
              flat={!selectChannel?.value}
              color={!selectChannel?.value ? "primary" : "negative"}
              disabled={!selectChannel?.value}
              onClick={() => {
                setSelectChannel({
                  label: null,
                  value: null,
                });
              }}
              label=""
              icon="x"
              iconSize="18px"
            />
          </div>
        </div>
        <div className="w-full md:flex align-middle gap-3 md:mt-0 mt-3">
          <SelectInput<BaseSelectItem, true>
            value={
              selectStart[0] ?? {
                label: t("selector.selectApt"),
                value: null,
              }
            }
            // placeholder="Seleziona locals da visualizzare.."
            onChange={(item) => {
              if (item) {
                setSelectStart([item]);
                if ([item].length > 0) {
                  setStartDate(dayjs().startOf("month").format("YYYY-MM-DD"));
                  setEndDate(dayjs().endOf("month").format("YYYY-MM-DD"));
                  setTimeLine(true);
                } else {
                  setStartDate(dayjs().format("YYYY-MM-DD"));
                  setEndDate(dayjs().add(15, "days").format("YYYY-MM-DD"));
                  setTimeLine(false);
                }
                localStorage.setItem(
                  "calendar_filteredLocals",
                  JSON.stringify([item])
                );
              } else {
                setSelectStart([]);
                setTimeLine(false);
                setStartDate(dayjs().format("YYYY-MM-DD"));
                setEndDate(dayjs().add(15, "days").format("YYYY-MM-DD"));
                localStorage.setItem(
                  "calendar_filteredLocals",
                  JSON.stringify([])
                );
              }
            }}
            options={locals?.results?.map((local: any) => {
              return {
                label: local.name,
                value: local.local_key,
              };
            })}
          />
          <div className="md:flex">
            {selectStart.length > 0 && (
              <Button
                data-tip={t("calendarChangeView")}
                label=""
                icon="calendar"
                iconSize="16px"
                onClick={() => {
                  setTimeLine((prev) => {
                    if (prev) {
                      setStartDate(dayjs().format("YYYY-MM-DD"));
                      setEndDate(dayjs().add(15, "days").format("YYYY-MM-DD"));
                      return !prev;
                    }
                    setStartDate(dayjs().startOf("month").format("YYYY-MM-DD"));
                    setEndDate(dayjs().endOf("month").format("YYYY-MM-DD"));
                    return !prev;
                  });
                }}
              />
            )}
            <Button
              className={"md:ml-1 ml-0"}
              flat={selectStart.length === 0}
              color={selectStart.length === 0 ? "primary" : "negative"}
              disabled={selectStart.length === 0}
              onClick={() => {
                setSelectStart([]);
                setTimeLine(false);
                setStartDate(dayjs().format("YYYY-MM-DD"));
                setEndDate(dayjs().add(15, "days").format("YYYY-MM-DD"));
                localStorage.setItem(
                  "calendar_filteredLocals",
                  JSON.stringify([])
                );
              }}
              label=""
              icon="x"
              iconSize="18px"
            />
          </div>
        </div>
      </div>
      {!timeLine && (
        <>
          <div className="flex border-t border-r">
            <div className="w-52 bg-gray-100 border-r flex items-center justify-between px-2"></div>
            <div className="flex-1 flex justify-between p-3">
              <div className="flex-1 text-sm font-bold capitalize">
                {dayjs(startDate).locale(i18n.language).format("MMMM YYYY")}
              </div>
              <div className="flex-1 flex justify-end mr-10 ">
                <Icon
                  onClick={() => {
                    const newDate = dayjs(startDate).subtract(15, "days");
                    setStartDate(newDate.format("YYYY-MM-DD"));
                    setEndDate(newDate.add(15, "days").format("YYYY-MM-DD"));
                  }}
                  className={"cursor-pointer"}
                  name={"double-arrow-left"}
                  size={"20px"}
                />
                <Icon
                  onClick={() => {
                    const newDate = dayjs(startDate).subtract(1, "days");
                    setStartDate(newDate.format("YYYY-MM-DD"));
                    setEndDate(newDate.add(15, "days").format("YYYY-MM-DD"));
                  }}
                  className={"cursor-pointer"}
                  name={"chevron-left"}
                  size={"20px"}
                />
                <Icon
                  onClick={() => {
                    const newDate = dayjs(startDate).add(1, "days");
                    setStartDate(newDate.format("YYYY-MM-DD"));
                    setEndDate(newDate.add(15, "days").format("YYYY-MM-DD"));
                  }}
                  className={"cursor-pointer"}
                  name={"chevron-right"}
                  size={"20px"}
                />
                <Icon
                  onClick={() => {
                    const newDate = dayjs(startDate).add(15, "days");
                    setStartDate(newDate.format("YYYY-MM-DD"));
                    setEndDate(newDate.add(15, "days").format("YYYY-MM-DD"));
                  }}
                  className={"cursor-pointer"}
                  name={"double-arrow-right"}
                  size={"20px"}
                />{" "}
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-52 bg-gray-100 border-r flex items-center justify-between px-2"></div>
            <div className="flex-1 flex">
              {days?.map((day, index) => (
                <div
                  key={index}
                  className={`relative flex-1 border-r text-center py-3 font-medium text-sm border-t ${
                    day.dayNumber === 0 ? "text-red-600" : "text-gray-500"
                  }`}
                >
                  {day.weekday} {day.day}
                  {index === 0 && (
                    <div
                      className={"border-r border-cyan-400"}
                      style={{
                        position: "absolute",
                        right: 0,
                        width: "1px",
                        top: 0,
                        height: "100vh",
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {!timeLine && locals && (
        <div className={"border-t"}>
          {locals?.results
            ?.filter((local: any) => {
              if (selectStart.length > 0) {
                return selectStart?.find(
                  (sel: any) => sel.value === local.local_key
                );
              }
              return local;
            })
            .map((local: any, index: number) => (
              <ApartmentRowNEW
                selectStart={selectStart}
                key={index}
                startDate={startDate}
                days={days}
                onView={(r) => {
                  setCurrentReservation(r);
                }}
                local={local}
                reservations={getLocalReservations(local)}
                objChannel={restrictions}
                refetchRestrictions={refetchRestrictions}
              />
            ))}
        </div>
      )}
      {timeLine && (
        <>
          {locals?.results
            ?.filter((local: any) => {
              if (selectStart.length > 0) {
                return selectStart?.find(
                  (sel: any) => sel.value === local.local_key
                );
              }
              return local;
            })
            .map((local: any, index: number) => (
              <SingleAptCalendar
                selectStart={selectStart}
                key={index}
                startDate={startDate}
                days={days}
                onView={(r) => {
                  setCurrentReservation(r);
                }}
                local={local}
                reservations={getLocalReservations(local)}
                setStartDate={(date: any) => changeStartLocal(date, local)}
                setEndDate={(date: any) => changeEndLocal(date, local)}
                objChannel={restrictions}
                refetchRestrictions={refetchRestrictions}
              />
            ))}
        </>
      )}
      {currentReservation && (
        <motion.div
          initial={{
            right: -200,
          }}
          animate={{
            right: 15,
          }}
          style={{
            top: "0",
            position: "fixed",
            height: "100vh",
            width: "100vw",
            zIndex: 99,
            padding: "2rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={(e) => {
            setCurrentReservation(null);
          }}
        >
          <div
            style={{
              borderRadius: "6px",
              overflowX: "auto",
              height: "100%",
              boxShadow: "0px 2px 16px rgba(0,0,0,0.3)",
              position: "relative",
              backgroundColor: "#FFF",
            }}
            className={"border border-gray-300 md:w-1/2 w-full"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                padding: "2rem",
              }}
            >
              <ReservationDetail
                reservationId={currentReservation.code}
              ></ReservationDetail>
            </div>
          </div>
        </motion.div>
      )}
      {openTabCal && (
        <SideBarChannel
          startDate={startDate}
          openTab={openTabCal}
          closeTab={setOpenTabCal}
        />
      )}
      <ModalOverride refetchRestrictions={refetchRestrictions} />
      {/* // TODO pagination {pageData && pageData.length > 1 ? (
        <div className="mt-5">
          <Pagination
            page={currentPage}
            pageSize={limitPage}
            totalItems={totalData}
            onPageUpdate={(value) => {
              if (!isLoading) {
                if (value === "+") {
                  setCurrentPage((prev) => prev + 1);
                  return;
                }
                if (value === "-") {
                  setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
                  return;
                }
                setCurrentPage(+value);
              }
            }}
          />
        </div>
      ) : null} */}
    </div>
  );
}
