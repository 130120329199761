import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  SingleApiResponseV3,
  BureaucracyResultPortaleAlloggiati,
  ReservationV3,
} from '../../types';
import {
  fetchBucketItem,
  fetchLastResultPortaleAlloggiati,
  fetchReservationv3,
  fetchReservationAction,
  sendSchedinaPortaleAlloggiati,
  fetchLastResultRicestat,
  sendSchedinaRicestat,
  fetchLastResultMossos,
  sendSchedinaMossos,
  fetchLastResultGuardiaCivil,
  sendSchedinaGuardiaCivil,
  sendSchedinaPoliciaNacional,
  fetchLastResultPoliciaNacional,
  fetchLastResultRossMille,
  fetchLastResultSef,
  sendSchedinaSef,
  fetchLastResultRadar,
  fetchLastResultUby,
  sendSchedinaUby,
  sendSesHospedajes,
  fetchLastResultSesHospedajes,
} from '../../shared/queries';
import Modal from '../Modal';
import ModalActions from '../ModalAction';
import ModalCard from '../ModalCard';
import ModalSection from '../ModalSection';
import Button from '../Button';
import { toast } from 'react-toastify';
import DetailRow from '../DetailRow';
import FormSectionTitle from '../FormSectionTitle';

export default function ReservationDocumentBuroCard({
  rsv,
  type,
}: {
  rsv: ReservationV3;
  type: string;
}) {
  const { t } = useTranslation();
  const [schedinaLoading, setSchedinaLoading] = useState(false);
  
  function getTypeTitle(type: any) {
    switch (type) {
      case 'portale-alloggiati':
        return 'Portale Alloggiati';
      case 'ses-hospedajes':
        return 'SES.Hospedajes';
      case 'sef':
        return 'Sef';
      case 'uby':
        return 'Uby';
      case 'policia-nacional':
        return 'Policía Nacional';
      case 'ross1000':
        return 'Ross 1000';
      case 'radar':
        return 'Radar';
      case 'mossos-esquadra':
        return "Mossos d'Esquadra";
      case 'guardia-civil':
        return 'Guardia Civil';
      case 'motourist':
        return 'MOTouristOffice';
      /* case 'paytourist':
        return 'Paytourist';
      case 'istat-trento':
        return 'Istat Trento'; */
      default:
        return '';
    }
  }

  function getResultQuery(): any {
    switch (type) {
      case 'portale-alloggiati':
        return fetchLastResultPortaleAlloggiati;
      case 'ross1000':
        return fetchLastResultRossMille;
      case 'radar':
        return fetchLastResultRadar;
      case 'sef':
        return fetchLastResultSef;
      case 'mossos-esquadra':
        return fetchLastResultMossos;
      case 'guardia-civil':
        return fetchLastResultGuardiaCivil;
      case 'policia-nacional':
        return fetchLastResultPoliciaNacional;
      case 'motourist':
        return fetchLastResultRicestat;
      case 'uby':
        return fetchLastResultUby;
      case 'ses-hospedajes':
        return fetchLastResultSesHospedajes;

      /* case 'paytourist':
        return fetchLastResultPaytourist;
      case 'istat-trento':
        return fetchLastResultIstatTrento; */
      default:
        break;
    }
  }

  function getSendMutationQuery(): any {
    switch (type) {
      case 'portale-alloggiati':
        return sendSchedinaPortaleAlloggiati;
      case 'ross1000':
        return sendSchedinaPortaleAlloggiati; // TODO
      case 'radar':
        return sendSchedinaPortaleAlloggiati; // TOCHANGE
      case 'sef':
        return sendSchedinaSef;
      case 'mossos-esquadra':
        return sendSchedinaMossos;
      case 'guardia-civil':
        return sendSchedinaGuardiaCivil;
      case 'policia-nacional':
        return sendSchedinaPoliciaNacional;
      case 'motourist':
        return sendSchedinaRicestat;
      case 'uby':
        return sendSchedinaUby;
      case 'ses-hospedajes':
        return sendSesHospedajes;
      /* case 'paytourist':
        return sendSchedinaPortaleAlloggiati; //TODO PAYTOURIST
      case 'istat-trento':
        return sendSchedinaPortaleAlloggiati; //TODO PAYTOURIST */
      default:
        break;
    }
  }

  function getResultText(): any {
    switch (type) {
      case "portale-alloggiati":
        if (result?.successfully) {
          return "OK";
        }
        return result?.result;
      case "ross1000":
        return result?.result;
      case "radar":
        return result?.result;
      case 'sef':
        return result?.result;
      case 'mossos-esquadra':
        return result?.result;
      case "mossos-esquadra":
        return result?.result;
      case "guardia-civil":
        return result?.result;
      case "policia-nacional":
        return result?.result;
      case "motourist":
        return result?.raw_result;
      case "uby":
        return result?.raw_result;
      case 'ses-hospedajes':
        return result?.result;

      /* case "paytourist":
        return result?.result;
      case "istat-trento":
        return result?.result; */
      default:
        return result?.result;
    }
  }
  
  function getFileType(): any {
    switch (type) {
      case 'portale-alloggiati':
        return "PORTALLOGTXT";
      case 'ross1000':
        return "PORTALLOGTXT";
      case 'radar':
        return "PORTALLOGTXT";
      case 'sef':
        return "MOSSOS";
      case 'mossos-esquadra':
        return "MOSSOS";
      case 'guardia-civil':
        return "MOSSOS";
      case 'policia-nacional':
        return "MOSSOS";
      case 'motourist':
        return "MOSSOS";
      case 'uby':
        return "MOSSOS";
      /* case 'paytourist':
        return "PORTALLOGTXT";
      case 'istat-trento':
        return "PORTALLOGTXT"; */
      default:
        return "PORTALLOGTXT";
    }
  }
  

  const [result, setResult] = useState<BureaucracyResultPortaleAlloggiati>();

  const [showResultDetail, setShowResultDetail] = useState<boolean>(false);

  const [sendConfirmDialog, setSendConfirmDialog] = useState<boolean>(false);

  const downloadMutation = useMutation(() =>
    fetchReservationAction({ action: getFileType(), resv_key: rsv.resv_key })
  );

  const sendSchedinaMutation = useMutation(
    () => getSendMutationQuery()(rsv.resv_key),
    {
      onSuccess: () => {
        setSendConfirmDialog(false);
        refetch_result_data();
        toast.success(t('general.operationCompleted'));
      },
      onError: () => {
        setSendConfirmDialog(false);
        refetch_result_data();
        toast.success(t('general.operationCompleted'));
      },
      onMutate:()=>{
        // console.log(false)
        setSchedinaLoading(false)
      }
    }
  );

  var { data: data_reservation, refetch: refetch_reservation } = useQuery<
    SingleApiResponseV3<ReservationV3>,
    Error
  >(['reservation', rsv.resv_key], () => fetchReservationv3(rsv.resv_key));

  var { data: result_data, refetch: refetch_result_data } = useQuery<
    SingleApiResponseV3<BureaucracyResultPortaleAlloggiati>,
    Error
  >([type, rsv.resv_key], () => getResultQuery()(rsv.resv_key));

  useEffect(() => {
    if (data_reservation && data_reservation.results) {
      //   const res = data_reservation.results;
      //   setReservation(res);
    }
    if (result_data && result_data.results) {
      const res = result_data.results;
      setResult(res);
    }
  }, [data_reservation, result_data]);

  return (
    <div>
      <div className="mb-8">
        {type !== 'ross1000' && type !== 'radar' && <FormSectionTitle
          borderless
          title={getTypeTitle(type)}
          // edit={true}
          onEdit={() => {
            // setUpdateDetails(true);
          }}
        ></FormSectionTitle>}
        {result && type !== 'ross1000' && type !== 'radar' ? (
          <>
            {Object.keys(result).length === 0 && (
              <DetailRow loading={false} label={t('buro.result')}>
                {t('buro.notYetSent')}
              </DetailRow>
            )}
            {type !== 'ses-hospedajes' && Object.keys(result).length > 0 && (
              <>
                {result.successfully ? (
                  <DetailRow loading={false} label={t('buro.result')}>
                    OK
                  </DetailRow>
                ) : (
                  <DetailRow loading={false} label={t('buro.result')}>
                    ERROR
                  </DetailRow>
                )}
                <DetailRow loading={false} label={t('buro.resultDetails')}>
                  <Button
                    onClick={() => setShowResultDetail(true)}
                    label={t('buro.showResultDetails')}
                  />
                </DetailRow>
              </>
            )}

              {type == 'ses-hospedajes' && Object.keys(result).length > 0 && (
              <>
                  <DetailRow loading={false} label={t('buro.result')}>
                    {result.status.toUpperCase()}
                  </DetailRow>
                <DetailRow loading={false} label={t('buro.resultDetails')}>
                  <Button
                    onClick={() => setShowResultDetail(true)}
                    label={t('buro.showResultDetails')}
                  />
                </DetailRow>
              </>
            )}
            <div className="mt-8">
              <Button
                onClick={() => setSendConfirmDialog(true)}
                label={t('buro.sendSchedina')}
                icon={'send'}
                loading={schedinaLoading}
              />
            </div>
            {type !== 'motourist' && type !=='sef' && type !== 'uby' && type !== 'ses-hospedajes' &&(
              <div className="mt-4">
                <Button
                  onClick={() => downloadMutation.mutate()}
                  label={t('buro.dowloadFile') + ' '+ getTypeTitle(type)}
                  icon={'document-download'}
                />
              </div>
            )}
            {result?.receipt_link && (
              <div className="mt-4">
                <Button
                  onClick={() => {
                    fetchBucketItem(
                      result.receipt_link,
                      result.file_name,
                      'pdf'
                    );
                  }}
                  label={t('buro.fileReceivedDownload')}
                  icon={'document-download'}
                />
              </div>
            )}
          </>
        ) : (
          <></>
        )}
        <Modal visible={showResultDetail}>
          <ModalCard title={t('buro.result')}>
            <ModalSection>
              <div className="p-4">
                <div className="flex mb-2 flex-wrap text-sm">
                  <div className={'xs:w-full text-gray-500'}>
                    {getResultText()}
                  </div>
                </div>
              </div>
            </ModalSection>
            <ModalActions
              onClose={() => {
                setShowResultDetail(false);
              }}
            ></ModalActions>
          </ModalCard>
        </Modal>
        <Modal visible={sendConfirmDialog}>
          <ModalCard title={t('buro.sendSchedinaApproval')}>
            <ModalSection>
              <ModalActions
                onClose={() => setSendConfirmDialog(false)}
                onSave={() => {
                  setSchedinaLoading(true);
                  setSendConfirmDialog(false)
                  sendSchedinaMutation.mutate();
                }}
                saveLabel={t('general.confirm')}
                isLoading={schedinaLoading}
              />
            </ModalSection>
          </ModalCard>
        </Modal>
      </div>
    </div>
  );
}
